@font-face {
    font-family: 'boorsok';
    src: url('./fonts/boorsok.ttf');  
}
body{
    background: #513daf;
}
/* PanelHeader */
.vkuiPanelHeader__in {
    background: #4a36a9;
}
 
/* End PanelHeader */


/* Placeholder */
.vkuiPlaceholder {
    background-image: url(./img/bg.jpg);
    background-size: cover;
    background-position: center;
    height: 650px;
}
.vkuiPlaceholder__header {
    font-family: 'boorsok';
    letter-spacing: 2px;
    color: #ffffff;
}
.vkuiPlaceholder__text {
    font-family: 'boorsok';
    letter-spacing: 2px;
    color: #ffffff;
}
/* End Placeholder */
 
.Bg {
    background-image: url(./img/bg.jpg);
    background-size: cover;
    background-position: center;
    min-height: 650px;
}
 
.predicted-time{
    font-size: 35px;
    margin-top: 10px;
}




.predicted-btn-rose {
    background: transparent !important;
    width: 150px;
}
.predicted-btn-rose .vkuiButton__in {
    background-image: url(./img/btn-rose.png);  
    background-repeat: no-repeat;
    font-family: 'boorsok';
    letter-spacing: 0.5px;
    color: #fff;
    padding-bottom: 10px;
    animation-name: rotate-and-translate;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.predicted-btn-rose .vkuiButton__in:hover {
    opacity: 0.8;
}

 
.predicted-btn-red {
    background: transparent !important;
    width: 150px;
}
.predicted-btn-red .vkuiButton__in {
    background-image: url(./img/btn-red.png);  
    background-repeat: no-repeat;
    font-family: 'boorsok';
    letter-spacing: 0.5px;
    color: #fff;
    padding-bottom: 10px;
}
.predicted-btn-red .vkuiButton__in:hover {
    opacity: 0.8;
}


.predicted-btn-violet {
    background: transparent !important;
    width: 150px;
}
.predicted-btn-violet .vkuiButton__in {
    background-image: url(./img/btn-violet.png);  
    background-repeat: no-repeat;
    font-family: 'boorsok';
    letter-spacing: 1px;
    color: #fff;
    padding-bottom: 10px;
}
.predicted-btn-violet .vkuiButton__in:hover {
    opacity: 0.8;
}
.vkuiSimpleCell__children {
    color: #fff;
}
.anim {
    animation-name: rotate-and-translate;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.vkuiButton--size-l .vkuiButton__after, .vkuiButton--size-l .vkuiButton__content:last-child {
    padding: 0px;
}
.money-img{
    vertical-align: middle;
}
.vkuiPlaceholder__in {
    padding-left: 25px;
    padding-right: 25px;
}
@keyframes rotate-and-translate {
    35% {transform: rotate(0) translate(0, 0);}
    40% {transform: rotate(5deg) translate(0, -2px);}
    45% {transform: rotate(-3deg) translate(0, -2px);}
    50% {transform: rotate(5deg) translate(0, -2px);}
    55% {transform: rotate(-3deg) translate(0, -2px);}
    60% {transform: rotate(5deg) translate(0, -2px);}
    65% {transform: rotate(-3deg) translate(0, -2px);}
    70% {transform: rotate(0) translate(0, 0);}
}


.animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
} 
.animation-container img {
    width: 300px;
    height: 300px;
    margin: 0 10px;
    opacity: 0;
    transition: opacity 0.5s linear;
    display: none;
}
.animation-container img.active {
    opacity: 1;
    display: block;
}

.vkuiSegmentedControlOption--checked{
    background: rgb(101,34,195);
    background: linear-gradient(0deg, rgba(101,34,195,1) 0%, rgba(183,82,224,1) 100%);
}
.vkuiSegmentedControl {
    background: #47413c;
}
.vkuiSimpleCell__indicator {
    color: #fff;
    font-family: 'boorsok';
    letter-spacing: 2px;
}
.box {
    border: solid #46413c;
    border-color: #46413c;
    background: rgb(101,34,195);
    background: linear-gradient(0deg, rgba(101,34,195,1) 0%, rgba(183,82,224,1) 100%);
    max-width: 320px;
    margin: 30px auto 30px auto;

    background: linear-gradient(-45deg, rgba(101,34,195,1), rgba(183,82,224,1), rgba(101,34,195,1), rgba(183,82,224,1));
    background-size: 400% 400%;
    animation: animate_gradient 8s ease infinite;

}
.box1 {
      border-width: 6px 6px 8px 5px;
      border-radius: 95% 4% 92% 5%/4% 95% 6% 95%;
      transform: rotate(2deg);
}  
.oddboxinner{
    margin:15px;
    transform: rotate(-2deg);
}




.menu-left {
    position: absolute;
    top: 60px;
    left: 10px;
    text-align: center;
    font-family: 'boorsok';
    letter-spacing: 2px;
    font-size: 10px;
}
.menu-left img {
    animation-name: rotate-and-translate;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    cursor: pointer;
}
.menu-left img:hover {
    opacity: 0.8;
}
.menu-right {
    position: absolute;
    top: 60px;
    right: 10px;
    text-align: center;
    font-family: 'boorsok';
    letter-spacing: 2px;
    font-size: 10px;
}
.menu-right img {
    animation-name: rotate-and-translate;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    cursor: pointer;
}
.menu-right img:hover {
    opacity: 0.8;
}


.vkuiHeader__content-in {
    font-family: 'boorsok';
    color: #fff;
    font-size: 20px;
    letter-spacing: 2px;
}



.boxBlock {
    border: solid #46413c;
    border-color: #46413c;
    background: rgb(101,34,195);
    background: linear-gradient(0deg, rgba(101,34,195,1) 0%, rgba(183,82,224,1) 100%);
    margin: 10px;
    border-width: 6px 6px 8px 5px;
    border-radius: 95% 4% 92% 5%/4% 95% 6% 95%;
    transform: rotate(2deg);
}
.boxOddboxinner{
    margin: 0px 5px 15px 5px;
    transform: rotate(-2deg);
}


.boxBlock .vkuiSimpleCell__typography {
    color: #ffffff;
}



 
@keyframes animate_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.count-anim{
    animation-name: rotate-and-translate;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes shrink {
    from {
      font-size: 24px; /* начальный размер текста */
    }
    to {
      font-size: 12px; /* конечный размер текста */
    }
}